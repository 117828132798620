<template>
  <div class="flex justify-center items-center bg-white py-10">
    <div class="text-center">
      <Logo class="mb-5 inline-block text-black" />
      <h1 class="font-semibold text-2xl">Log in</h1>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="logInUser" class="text-left mt-10">
          <div class="mb-5">
            <label for="email" class="block mb-2">Email Address</label>
            <ValidationProvider
              name="Email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <input
                id="email"
                type="email"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-72"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="email@gmail.com"
                v-model="auth.email"
              /><br />
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="mb-5">
            <label for="password" class="block mb-2">Password</label>
            <ValidationProvider
              name="Password"
              rules="required"
              v-slot="{ errors }"
            >
              <div
                class="bg-gray-100 px-3 rounded-md w-72 flex justify-between items-center"
                :class="errors[0] ? 'border border-red-400' : 'border-none'"
              >
                <input
                  id="password"
                  :type="showPW ? 'text' : 'password'"
                  class="py-2 bg-transparent w-full border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
                  placeholder="Password"
                  v-model="auth.password"
                />
                <div @click="showPW = !showPW">
                  <EyeCloseIcon
                    class="w-5 opacity-40 cursor-pointer"
                    v-if="showPW"
                  />
                  <EyeIcon class="w-5 opacity-40 cursor-pointer" v-else />
                </div>
              </div>
              <span class="block text-red-400 mt-3" v-if="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="flex justify-between text-sm mb-10">
            <div class="">
              <input id="remember" type="checkbox" v-model="auth.remember" />
              <label for="remember" class="ml-2">Remember me</label>
            </div>
            <router-link to="/reset-pw" class="text-primary">
              Reset Password?
            </router-link>
          </div>
          <button
            class="w-full text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :class="invalid ? 'bg-gray-300' : 'bg-primary'"
            :disabled="invalid || submitting"
          >
            {{ submitting ? "Please wait..." : "Log in" }}
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { TOKEN } from "@/utils/constants";
import { login } from "@/services/auth";

export default {
  name: "LoginPage",
  components: {
    Logo: () => import("@/assets/imgs/Logo.vue"),
    EyeIcon: () => import("@/assets/icons/EyeIcon.vue"),
    EyeCloseIcon: () => import("@/assets/icons/EyeCloseIcon.vue"),
  },
  data() {
    return {
      auth: {
        email: "",
        password: "",
        remember: false,
      },
      showPW: false,
      submitting: false,
    };
  },
  methods: {
    async logInUser() {
      this.submitting = true;
      try {
        const response = await login({
          email: this.auth.email,
          password: this.auth.password,
        });
        localStorage.setItem(TOKEN, response.data.token);
        localStorage.setItem(
          "ROLE",
          JSON.stringify(response.data.staff.staffroles)
        );
        if (response.data.staff.lastpassword_change === null) {
          this.$toast.warning("Please set a new password for your account");
          window.location.replace("/register");
        } else {
          this.$toast.success("You're logged In");
          window.location.replace("/app/dashboard");
        }
      } catch (error) {
        if (error.response.data) {
          this.$toast.error(error.response.data.message || error);
        } else {
          this.$toast.error("Network Error, please try again");
        }
        console.log(error);
      }
      this.submitting = false;
    },
  },
};
</script>
